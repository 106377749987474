import React from "react"

const PocinkovaneCevi = () => {
  return (
    <div className="box" style={{ width: "100%" }}>
      <table className="table is-bordered is-fullwidth is-hoverable has-text-centered">
        <thead>
          <tr>
            <th className="is-link has-text-centered" colSpan={3}>
              POCINKOVANE CEVI
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="has-text-link">Spoljni prečnik</td>
            <td className="has-text-link">Težina (kg)</td>
          </tr>
          <tr>
            <td> R1/2″ (6m)</td>
            <td>8,0</td>
          </tr>
          <tr>
            <td>R3/4″ (6m)</td>
            <td>10,0</td>
          </tr>
          <tr>
            <td>R 1″ (6m)</td>
            <td>15,0</td>
          </tr>
          <tr>
            <td>R5/4″ (6m)</td>
            <td>19,0</td>
          </tr>
          <tr>
            <td>R6/4″ (6m)</td>
            <td>22,0</td>
          </tr>
          <tr>
            <td>R 2″ (6m)</td>
            <td>30,5</td>
          </tr>
          <tr>
            <td>R2,5″ (6m)</td>
            <td>43,0</td>
          </tr>
          <tr>
            <td>R 3″ (6m)</td>
            <td>53,0</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default PocinkovaneCevi
